#header-sort-wrapper{
    padding: 1rem;
    /* border-bottom: var(--nice-green) 1px solid; */
}

#header-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* button */
#new-post{
    font-size: 12pt;
    padding: .25rem .75rem;
    border-radius: 5pt;
    border: var(--text) solid 2px;
    background-color: var(--nice-green);
    color: var(--background)
}

#search-sort-wrapper{
    /* margin-top: 1rem; */
    display: flex;
    align-items:center;
    justify-content: space-between;
}

#sort-wrapper {
    padding:0;
    margin: .5rem 0
}

#sort-buttons-wrapper{
    margin-left: 1rem;
    font-size: 12pt;
    /* padding: .25rem .75rem; */
    border-radius: 3pt;
    border: var(--text) solid 2px;
    /* background-color: var(--nice-green); */
    color: var(--background)
}

.sort-button{

    font-size: 12pt;
    padding: .25rem .75rem;
    background-color: var(--nice-green);
    color: var(--background)
}
.sort-button:hover, .not-sorted:hover{
    color: var(--text);
    background-color: var(--lt-green);
}

.not-sorted{
    font-size: 12pt;
    padding: .25rem .75rem;
    color: var(--nice-green)
}

#posts-list-wrapper{
    padding:1rem;
}

#single-post-wrapper{
    border-bottom: 1px solid var(--lt-green);
}

#post-title{
    font-size: 18pt;
    padding-top:1rem;
    /* color: var(--nice-green); */
}

#post-title:hover {
    font-size: 18pt;
    color: var(--nice-green);
}

#body-preview{
    padding: 1rem 0;
}

#post-info{
    text-align: end;
}



#comment-count{
    color: var(--nice-green)
}
