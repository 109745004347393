#signup-modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 13pt;
    width: 37vw;
    border: var(--text) 2px solid;
    background-color: var(--background);
}

#signup-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;
}

.input-field {
    border: var(--text) 1px solid;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 3px;
}

.errors-info{
    color: red;
}



#signup-button-wrapper>button {
    margin: .5rem 0;
    background-color: var(--nice-green);
    border-radius: 8px;
    color: var(--background);
    padding:.5rem;
}

#signup-button-wrapper>button:hover {
    /* color: var(--text); */
    background-color: var(--med-green);
    cursor: pointer;
}

#signup-button-wrapper>#disabled{
    background-color:gray;
    /* color: red ; */
}
