
#about-us-wrapper{
    width: 85rem;
    padding: 1rem;
    /* height: 79vh; */
}

#isric-link{
    color: var(--nice-green);
    text-decoration:underline ;
}

#interpretive-table{
    padding: .5rem;
    border: solid 1px var(--nice-green);
    border-radius: 8px;
    width: 44rem
}

#interpret-table-parameter{
    font-size: 14pt;
}

#table-target-value{
    font-size: 14pt;
}
