#footer-wrapper{
    height: 4rem;
    border-top: solid 1px var(--nice-green);
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    z-index: 20;
}

#developer-section-wrapper{
    width: 10rem
}

#links-wrapper, #nav-links-wrapper{
    margin-top: 1rem;
}

#links-wrapper>a{
    margin: 0 1.5rem;
    /* display: flex; */
    /* justify-content: space-between; */
}

#footer-nav-wrapper{
    width: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#nav-links-wrapper{
    display: flex;
    justify-content: space-around;
    width: 100%;

}
