#left-side-bar {
    
    margin-top:0;
    display: flex;
    justify-content: space-between;
    /* border-left: var(--nice-green)solid 1px; */
    border-right: var(--nice-green)solid 1px;

    /* border-radius: 10px; */
    width: 15vw;
    padding: 1%;
    height: 91vh;
    margin-left: 5px;
    position: sticky;
    top: 2.9rem;
    bottom: 5rem;
    /* z-index:-1 */
}



.highlight>a:hover,
.link:hover {
    /* font-weight: bolder; */
    color: var(--nice-green);
    /* border: red solid 1px */
    /* padding: 4% 0% */
}
.large-links {
    margin-top: .75rem;
    font-size: 18pt;
}

.large-links:hover {
    color: var(--nice-green);
}

#smaller-links{
    margin-top: .25rem;
    margin-left: 1rem;
    font-size: 14pt;
}
