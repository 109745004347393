#posts-whole-wrapper{
    width: 85vw
}


#upper-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    /* align-items: center; */
}

#buttons-wrappers {
    display: flex;
    padding-top: 0;
    /* flex-direction: column; */
    /* font-size: 12pt; */
    /* padding: .25rem .75rem; */
    border-radius: 3pt;
    /* border: var(--text) solid 2px; */
    /* background-color: var(--nice-green); */
    color: var(--background);
}

#green-button-wrapper> button {
    font-size: 12pt;
    padding: .25rem .75rem;
    margin-left: .5rem;
    background-color: var(--nice-green);
    color: var(--background);
    border-radius: 3pt;
    border: var(--text) solid 1px;
}

#green-button-wrapper>button:hover{
    /* color: var(--text); */
    background-color: var(--med-green);
    cursor: pointer;
}

#buttons-wrappers>button {
    margin:0;
    height: fit-content;
    font-size: 12pt;
    padding: .25rem .75rem;
    margin-left: .5rem;
    background-color: var(--delete);
    color: var(--background);
    border-radius: 3pt;
    border: var(--text) solid 1px;
}

#buttons-wrappers>button:hover{
    color: var(--background);
    background-color: var(--redder);
    cursor: pointer;
}


#post-title {
    font-size: 18pt;
    color: var(--nice-green);
}


#post-info {
    text-align: end;
}

#comments-wrapper {
    border-top: var(--lt-green) solid 1px;
}

#comments-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}
#comments-header-wrapper>div{
    padding-top: 1rem;
}

#single-comment-wrapper{
    /* padding-bottom: 0.75rem; */
    border-bottom: var(--lt-green) solid 1px;
}

.voted{
    color: var(--delete);
    /* border: 1px solid var(--nice-green); */
    /* border-radius: 50px */
}

#votes-body-wrapper{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 1rem 0;
}

#voting-buttons-wrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-right: 1rem;
}

#voting-buttons-wrapper>h2{
    padding: .5rem
}

#hidden{
    display: none
}

#single-comment-header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .55rem
}

#single-comment-header-wrapper>div {
    padding-top:0;
}

#single-comment-header-wrapper>div>div>* {
    margin-left: 0;
}
