#compass{
  font-size:16pt
}
#compass:hover{
  color: var(--nice-green);
}


.profile-dropdown {
  position: absolute;
  margin-top: .45rem;
  /* margin-right: -4rem; */
}

#login-button-wrapper {
  /* margin-top: .35rem; */
  margin-left: -2rem;
  display: flex;
  flex-direction: column;
  border: var(--other) solid 2px;
  border-radius: 10pt;
  padding: .4rem .4rem;
  width: 4rem;
  background-color: var(--background);
  /* justify-content: flex-end; */
  /* border:red solid 1px */
}

#logged-button-wrapper {
  /* margin-top: .35rem; */
  margin-left: -8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: var(--other) solid 2px;
  border-radius: 10pt;
  padding: .4rem .4rem;
  width: 10rem;
  background-color: var(--background);
  /* justify-content: flex-end; */
  /* border:red solid 1px */
}
.hidden {
  display: none;
}

.weighty{
  font-weight: bolder;
  font-size: 22pt;
  padding:0;
  margin:0;
  /* border: red solid 1px; */
}

#sticky-wrapper{
  position: sticky;
  top:0px;
  z-index: 100
}

#navigation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: red solid 1px; */
  background-color: var(--background);
  margin: 5px;
  margin-bottom: 0;
  padding: .25rem 1rem;
  border-bottom: var(--nice-green) 1px solid;
}

#left-side-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


#logo{
  font-size: 20pt;
  /* padding:0 1rem; */
}
#logo:hover {
  color: var(--nice-green);
}

#logo-li{
  /* border: red solid 1px; */
  padding:0 1rem;
  margin-top:-.35rem;
}

/* the one that pops up when you click the compass */
#sidebar{
/* border: red solid 1px; */
/* background-color: ; */
  position:absolute;
  margin-top: 9rem;
  margin-left: -1vw;
  border: var(--other) solid 2px;
  border-radius: 10pt;
  padding: .4rem .2rem;
  z-index: 100;
  background-color: var(--background);
}

.link{
  font-size: 14pt;
}

.highlight>a:hover, .link:hover{
  /* font-weight: bolder; */
  color: var(--nice-green);
  /* border: red solid 1px */
  /* padding: 4% 0% */
}




#form-wrapper{
  display: flex;
  align-items: center;
  width: 45vw;
}


#search-input {
  margin-left:1.5rem;
  border: var(--text) solid 1px;
  border-radius: 5px;
  padding: 3px;
  width: 100%;
}

#submit-button{
  border: var(--text) solid 1px;
  padding: 3px;
  margin-left: .5rem;
  border-radius: 5px;
}

/* for the user button stuff */

#user-image{
  /* width:2rem; */
  height:2rem;
  /* border: red solid 1px */
}

#user-button{
  border: var(--text) 1px solid;
  border-radius: 30px;
  padding: 7px;
  font-size: 16pt;
  margin-bottom: 0;
  background-color: var(--background);
}

#user-button:hover{
  /* font-weight: bolder; */
  color: var(--nice-green);
  /* border: red solid 1px */
  /* padding: 4% 0% */
}
