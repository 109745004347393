.map-container {
    height: 400px
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: var(--background);
    /* padding: 6px 12px; */
    /* font-family: monospace; */
    z-index: 1;
    position: absolute;
    /* top: 11.5vh; */
    /* left: 17.5vw; */
    padding: 8px;
    margin: 5px;
    border-radius: 4px;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center{
    background-color: transparent;
    /* border: red solid 1px */
}
